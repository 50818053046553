import React, {useEffect, useRef, useState} from "react";
import Draggable from "react-draggable";
import {Dialog, DialogContent, DialogTitle, IconButton, Paper, Typography} from "@material-ui/core";
import {ArrowLeft, ArrowRight, Close} from "@material-ui/icons";

function PaperComponent(props) {
  const containerRef = useRef(null);
  return (
    <Draggable nodeRef={containerRef} handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper{...props} style={{ pointerEvents: "auto", width: "800px", height: "700px" }} ref={containerRef}/>
    </Draggable>
  );
}

const UploadSlider = ({ open, onClose, uploads, initialUpload }) => {
  const containerRef = useRef(null);
  const [container, setContainer] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [currentUpload, setCurrentUpload] = useState({});
  const [hasSetInitialUpload, setHasSetInitialUpload] = useState(false);
  const pdfList = uploads.map((item) => item.upload).filter((upload) => upload.mimeType === "application/pdf");

  useEffect(() => {
    setContainer(containerRef.current);
  }, []);

  useEffect(() => {
    if (open && !hasSetInitialUpload) {
      setCurrentIndex(pdfList.findIndex(upload => upload.id === initialUpload.id));
      setCurrentUpload(initialUpload);
      setHasSetInitialUpload(true);
    }
  }, [open, initialUpload, pdfList, hasSetInitialUpload]);

  useEffect(() => {
    if (currentIndex !== null) {
        setCurrentUpload(pdfList[currentIndex]);
    }
  }, [currentIndex, pdfList]);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => {
      return prevIndex < pdfList.length - 1 ? prevIndex + 1 : prevIndex;
    });
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => {
      return prevIndex > 0 ? prevIndex - 1 : prevIndex;
    });
  };

  return (
    <div
      ref={containerRef}
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 1500,
        pointerEvents: "none",
      }}
    >
      <Dialog
        open={open}
        onClose={onClose}
        disableBackdropClick
        disableEscapeKeyDown
        hideBackdrop
        disableEnforceFocus
        disableAutoFocus
        disableRestoreFocus
        disableScrollLock
        PaperComponent={PaperComponent}
        maxWidth="lg"
        container={container}
        fullWidth={true}
      >
        {currentUpload && (
            <>
              <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                <>
                  <Typography>{currentUpload.originalFilename}</Typography>
                  <IconButton onClick={onClose} style={{ position: "fixed", right: 0, top: 0 }}>
                    <Close fontSize="large" />
                  </IconButton>
                </>
              </DialogTitle>
              <DialogContent
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  overflow: "hidden"
                }}
              >
                <IconButton
                  onClick={handlePrev}
                  disabled={currentIndex === 0}
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "10px",
                    transform: "translateY(-50%)",
                  }}
                >
                  <ArrowLeft fontSize="large"/>
                </IconButton>
                <iframe src={currentUpload.link}
                        width="700"
                        height="600"
                />
                <IconButton
                  onClick={handleNext}
                  disabled={currentIndex === pdfList.length - 1}
                  style={{
                    position: "absolute",
                    top: "50%",
                    right: "10px",
                    transform: "translateY(-50%)",
                  }}
                >
                  <ArrowRight fontSize="large" />
                </IconButton>
              </DialogContent>
            </>)}
      </Dialog>
    </div>
  );
};

export default UploadSlider;