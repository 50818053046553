import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";
import { useEffect, useState } from "react";

const UploadDialog = (props) => {
  const { open, images, image, onClose } = props;
  const [startIndex, setStartIndex] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    if (images.length > 0 && image) {
      const index = images.indexOf(image.link);
      if (index !== -1) {
        setStartIndex(index);
      }
    }
  }, [images, image]);

  useEffect(() => {
    if (startIndex !== null) {
      setDialogOpen(open);
    }
  }, [startIndex]);

  return (
    <>
      {dialogOpen && startIndex !== null && (
        images.length > 1 ? (
            <Lightbox
              images={images}
              startIndex={startIndex}
              onClose={() => {
                setDialogOpen(false);
                onClose();
              }}
            />
          ): (
            <Lightbox
              image={images}
              onClose={() => {
                setDialogOpen(false);
                onClose();
              }}
            />
          ))}
    </>
  );
};

export default UploadDialog;